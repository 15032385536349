




























































































































import {
  API_REMIND_UN_READ_COUNTS,
  API_OPENED_SYSTEM_INFO,
  API_OPENED_WEB_NOTICE
} from "@/configs/Apis";
import { isEmptyOrWhiteSpace } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import AccountInfo from "@/views/account/AccountInfo.vue";
import ChangePassword from "@/views/account/ChangePassword.vue";
import SystemInfo from "@/views/account/SystemInfo.vue";
import SystemHelp from "@/views/account/SystemHelp.vue";
import { Badge, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import debounce from "lodash/debounce";
import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

Vue.use(Badge)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem);

declare interface RemindMessageDO {
  title?: string;
  content?: string;
  readFlag?: boolean;
}

declare interface RemindMessageData {
  newMessageCount?: number;
  messages?: Array<RemindMessageDO>;
}

@Component({
  components: {
    AccountInfo,
    ChangePassword,
    SystemHelp,
    SystemInfo
  },
  beforeRouteEnter(to: Route, from: Route, next: (vm?: any) => void) {
    next((vm: any) => {
      let path = to.path;
      if (path[0] === "/") {
        path = path.replace(/^\/|\/$/g, "");
      }
      if (vm.activedMenuPath !== path) {
        vm.activedMenuPath = path;
      }
      if (to.matched && to.matched.length >= 2) {
        vm.activedMenuPath = to.matched[1].path.replace("/", "");
      }
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: (vm?: any) => void) {
    let path = to.path;
    if (path[0] === "/") {
      path = path.replace(/^\/|\/$/g, "");
    }
    const vm = this as any;
    if (to.matched && to.matched.length >= 2) {
      path = to.matched[1].path.replace("/", "");
    }
    if (vm.activedMenuPath !== path) {
      vm.$nextTick(() => (vm.activedMenuPath = path));
    }
    next();
  }
})
export default class Home extends Vue {
  $refs!: {
    accountInfo: AccountInfo;
    changePassword: ChangePassword;
    systemInfo: SystemInfo;
    systemHelp: SystemHelp,
    menuwrapper: HTMLDivElement;
    topmenu: HTMLUListElement;
  };

  searchValue = "";

  activedMenuPath = "patents";

  remindMessageData: RemindMessageData = {};

  remindInterval = 0;

  extendedLogs: Array<any> = [];

  unlockUserDialogVisible = false;

  showMoveLeft = false;
  showMoveRight = false;

  currentLeft = 0;
  scrollableMaxWidth = 0;

  showLicenseWarning = false;
  licenseWarningMessage = "";

  showAdsInfo = false;
  adsInfo = "";

  get userEntity() {
    return this.$store.getters["user/user"] || {};
  }

  get userName() {
    return this.userEntity.name;
  }

  get accountBalance() {
    return this.userEntity.accountBalance;
  }

  get userLevel() {
    const levelMap: Array<any> = this.$store.getters["enums/getEnumsByKey"](
      "UserType"
    );
    const level = this.userEntity.userType;
    const foundItems = levelMap.filter(item => item.enumValue === level);
    if (foundItems && foundItems.length) {
      return foundItems[0].label;
    }
    return "";
  }

  get menus() {
    const user = this.userEntity;
    const menus: Array<any> = this.$store.getters["menu/menus"](user);
    menus.forEach(menu => {
      menu.route = { name: menu.path };
    });
    return menus;
  }

  get appTitle() {
    return process.env.VUE_APP_TITLE;
  }

  logout() {
    this.$store
      .dispatch("user/logout")
      .then(() => this.$router.push({ name: "login" }));
  }

  @Provide()
  isAdminUser() {
    return this.userEntity.userType === "ADMIN";
  }

  created() {
    const taskId = window.localStorage.getItem(
      `patentsync-taskid-${this.userEntity.id}`
    );
    if (!isEmptyOrWhiteSpace(taskId)) {
      this.$store.commit("patentsync/taskId", taskId);
    }
    // this.validateUserLocked();

    Ajax.queryData(API_OPENED_SYSTEM_INFO, {}, "GET").then(data => {
      const license: any = JSON.parse(data.license || "{}") || {};
      if (!license.expiredDate) {
        return;
      }
      const days: number = Math.floor(
        (new Date(license.expiredDate).getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24)
      );
      if (days < 15) {
        this.showLicenseWarning = true;
        this.licenseWarningMessage = `产品授权还有 ${days} 天就要到期, 过期后会禁止登录, 如需延长使用期限, 请联系我们, 电话: 13331285063`;
      }
    });

    Ajax.queryData(API_OPENED_WEB_NOTICE, {}, "GET").then(notice => {
      if (notice && notice.content) {
        this.showAdsInfo = true;
        this.adsInfo = notice.content;
      }
    });
  }

  mounted() {
    this.startFetchRemind();
    this.execTopMenu = debounce(this.execTopMenu.bind(this), 500);
    window.addEventListener("resize", this.execTopMenu);
    this.execTopMenu();

    document.title = process.env.VUE_APP_TITLE;
  }

  destroyed() {
    window.clearInterval(this.remindInterval);
    window.removeEventListener("resize", this.execTopMenu);
  }

  execTopMenu() {
    const wrapper = this.$refs.menuwrapper as HTMLDivElement;
    const menu = (this.$refs.topmenu as any)?.$el as HTMLUListElement;
    if (!wrapper || !menu) {
      return;
    }
    this.currentLeft = 0;
    const wrapperWidth = wrapper.clientWidth;
    const menuWidth = menu.scrollWidth;

    if (wrapperWidth >= menuWidth) {
      this.showMoveLeft = false;
      this.showMoveRight = false;
    } else {
      this.showMoveRight = true;
      this.showMoveLeft = true;

      const left = wrapper.scrollLeft;

      this.scrollableMaxWidth = menuWidth - wrapperWidth;

      if (left === 0) {
        this.showMoveLeft = false;
      }
      if (left === this.scrollableMaxWidth) {
        this.showMoveRight = false;
      }
    }
  }

  moveLeft() {
    const wrapper = this.$refs.menuwrapper as HTMLDivElement;
    if (!wrapper) {
      return;
    }

    this.showMoveRight = true;
    this.showMoveLeft = true;
    let newLeft = this.currentLeft - 144;
    if (newLeft <= 0) {
      newLeft = 0;
      this.showMoveLeft = false;
    }
    this.currentLeft = newLeft;
  }

  moveRight() {
    const wrapper = this.$refs.menuwrapper as HTMLDivElement;
    if (!wrapper) {
      return;
    }
    this.showMoveRight = true;
    this.showMoveLeft = true;
    let newLeft = this.currentLeft + 144;
    if (newLeft >= this.scrollableMaxWidth) {
      this.showMoveRight = false;
      newLeft = this.scrollableMaxWidth;
    }
    this.currentLeft = newLeft;
  }

  onUserInfoCommand(command: string) {
    if (command === "logout") {
      this.logout();
    } else if ((this.$refs as any)[command]) {
      (this.$refs as any)[command].open();
    }
  }

  startFetchRemind() {
    if (!window.WebSocket) {
      this.startFetchRemindWithAjax();
    } else {
      this.startFetchRemindWithWebSocket();
    }
  }

  startFetchRemindWithAjax() {
    this.getUnReadRemindMessage();
    this.remindInterval = window.setInterval(() => {
      setTimeout(() => {
        this.getUnReadRemindMessage();
      }, 1);
    }, 30000);
  }

  getUnReadRemindMessage() {
    Ajax.queryData(API_REMIND_UN_READ_COUNTS, null, "GET", false)
      .then(rs => {
        this.remindMessageData = {
          newMessageCount: rs.count,
          messages: rs.data
        };
      })
      .catch(() => "");
  }

  DD_SOCKET: any = null;
  DD_SOCKET_DEBUG = false;
  DD_SOCKET_RETRY_TIMES = 1000;
  DD_SOCKET_MAX_RETRY_TIMES = 3600000;

  startFetchRemindWithWebSocket() {
    setTimeout(() => {
      this.connect_ws_server();
    }, 100);
  }

  re_connect_ws() {
    this.DD_SOCKET_RETRY_TIMES += 1000;
    if (this.DD_SOCKET_RETRY_TIMES > this.DD_SOCKET_MAX_RETRY_TIMES) {
      return;
    }
    setTimeout(() => {
      this.connect_ws_server();
    }, this.DD_SOCKET_RETRY_TIMES);
  }

  connect_ws_server() {
    if (!this.DD_SOCKET) {
      const url = `${
        document.location.protocol === "https:" ? "wss" : "ws"
      }://${document.location.host}/api/ws/remind`;
      this.DD_SOCKET = new WebSocket(url);
      this.DD_SOCKET.onmessage = this.ws_onmessage;
      this.DD_SOCKET.onopen = this.ws_onopen;
      this.DD_SOCKET.onclose = this.ws_onclose;
    }
    if (this.DD_SOCKET.readyState === WebSocket.OPEN) {
      const user = this.$store.getters["user/user"] || {};
      const tid = user.tenantId || 0;
      const uid = user.id || 0;
      this.DD_SOCKET.send(`${tid}#${uid}`);
    } else {
      console.info("The socket is not open.");
      this.re_connect_ws();
    }
  }

  ws_onmessage(event: any) {
    var _data = event.data;
    if (this.DD_SOCKET_DEBUG) {
      console.info("websocket receive:" + _data);
    }
    var rs = JSON.parse(_data);
    this.remindMessageData = {
      newMessageCount: rs.count,
      messages: []
    };
  }

  ws_onopen(event: any) {
    console.info("websocket opened!");
  }

  ws_onclose(event: any) {
    console.info("websocket closed.");
    this.DD_SOCKET = null;
    this.re_connect_ws();
  }

  @Watch("$store.state.patentsync.taskId")
  startPatentSync() {
    if (isEmptyOrWhiteSpace(this.$store.state.patentsync.taskId)) {
      return;
    }
    return this.$store.dispatch("patentsync/queryProgress");
  }

  gotoIndex() {
    // this.$router.push({ name: "index" });
  }

  gotoMessage() {
    this.$router.push({ name: "messages" });
  }

  showHelp() {
    (this.$refs as any).systemHelp.open();
  }
}
