import Vue from "vue";
import Router, { Route } from "vue-router";
import store from "./stores";
import { isEmpty } from "./utils/Common";
import Home from "./views/Home.vue";

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { auth: true },
      redirect: { name: "patents" },
      children: [
        {
          path: "activities",
          name: "activities",
          component: () => import("./views/activity/ActivityIndex.vue")
        },
        {
          path: "patents",
          name: "patents",
          component: () => import("./views/patent/PatentIndex.vue")
        },
        {
          path: "users",
          name: "users",
          component: () => import("./views/user/UserIndex.vue")
        },
        {
          path: "messages",
          name: "messages",
          component: () => import("./views/message/MessageIndex.vue")
        },
        {
          path: "settings",
          name: "settings",
          component: () => import("./views/setting/SettingIndex.vue"),
          redirect: { name: "managers" },
          children: [
            {
              path: "managers",
              name: "managers",
              component: () => import("./views/setting/UserList.vue")
            },
            {
              path: "permissions",
              name: "permissions",
              component: () => import("./views/setting/PermissionSetting.vue")
            },
            {
              path: "org-info",
              name: "orgInfo",
              component: () =>
                import("./views/setting/OrgInfoSetting.vue")
            },
            {
              path: "weixin-api",
              name: "weixinApi",
              component: () =>
                import("./views/setting/WeixinApiSetting.vue")
            },
            {
              path: "push-rule",
              name: "pushRule",
              component: () =>
                import("./views/setting/PushRuleSetting.vue")
            },
            {
              path: "system-logs",
              name: "systemLogs",
              component: () => import("./views/setting/SystemLogList.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/auth/Login.vue")
    },
    {
      path: "/find-password",
      name: "findPassword",
      component: () => import("./views/auth/FindPassword.vue")
    },
    {
      path: "/change-password",
      name: "changePassword",
      component: () => import("./views/auth/ChangePassword.vue")
    },
    {
      path: "/register",
      name: "register",
      component: () => import("./views/auth/Register.vue")
    },
    {
      path: "*",
      name: "404",
      component: () => import("./views/404.vue")
    }
  ]
});

function loadRequiredData(to: Route) {
  const data = store.getters["user/user"];
  const enums = store.getters["enums/enums"];
  const task = [];
  if (isEmpty(data) || isEmpty(enums)) {
    // 获取用户数据
    if (isEmpty(data)) {
      task.push(store.dispatch("user/getUser"));
    }
    if (isEmpty(enums)) {
      task.push(store.dispatch("enums/getEnums"));
    }
  }
  return Promise.all(task);
}

router.beforeEach((to, from, next) => {
  const routers = to.matched.filter(r => r.meta.auth === true);
  if (!routers.length) {
    next();
  } else {
    loadRequiredData(to).then(() => {
      const data = store.getters["user/user"];
      if (isEmpty(data)) {
        next("/login");
      } else {
        next();
      }
    });
  }
});

export default router;
