import { API_PATENT, API_PATENT_LOGIC_DELETE, API_PATENT_RECOVER, API_PATENT_REMARK, API_PATENT_TREE } from "@/configs/Apis";
import { Fetcher as Ajax } from "@/utils/Request";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import { Module } from "vuex";
import baseModule from "./common";

const newModule: Module<any, any> = merge(cloneDeep(baseModule), {
  actions: {
    getPatentTree({ commit }: any) {
      return Ajax.queryData(API_PATENT_TREE, {}, "GET", false)
        .then(data => {
          let count = 0;
          function execCount(item: any) {
            if (item && item.children) {
              item.children.forEach((child: any) => {
                count += child.count;
              });
            }
          }
          if (data && data.length) {
            execCount(data[1]);
          }

          const showCount = 10;
          data.forEach((item: any) => {
            if (!item.children) {
              return;
            }
            if (item.children.length <= showCount) {
              item.showData = { data: [].concat(item.children) };
            } else {
              item.showData = {
                index: 0,
                size: showCount,
                data: item.children.slice(0, showCount)
              };
            }
          });

          commit(
            "setPatentTree",
            [
              {
                title: "全部",
                count: count,
                icon: "all-data",
                patentFilter: {}
              }
            ].concat(data)
          );
        })
        .catch(() => {
          commit("setPatentTree", [
            {
              title: "全部",
              count: 0,
              icon: "all-data",
              patentFilter: {}
            }
          ]);
        });
    },
    addPatent(store: any, payload: any) {
      return Ajax.saveData(API_PATENT, payload, "POST");
    },
    updatePatent(store: any, payload: any) {
      return Ajax.saveData(API_PATENT + "/" + payload.id, payload, "PUT");
    },
    deletePatent(store: any, filter: any) {
      return Ajax.saveData(API_PATENT, filter, "DELETE");
    },
    logicDeletePatent(store: any, filter: any) {
      return Ajax.saveData(API_PATENT_LOGIC_DELETE, filter, "DELETE");
    },
    recoverPatent(store: any, filter: any) {
      return Ajax.saveData(API_PATENT_RECOVER, filter, "PUT");
    },
    remark(store: any, payload: any) {
      return Ajax.saveData(`${API_PATENT_REMARK}/${payload.id}`, {remark: payload.remark}, "PUT");
    }
  },
  mutations: {
    setPatentTree(state: any, data: any) {
      state.patentTree = data;
    }
  }
});

const oldState = newModule.state as () => Object;

newModule.state = () => ({ ...oldState(), patentTree: null });

export default newModule;
